import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', function () {
  new Swiper('#homepage-container-slide', {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  });
});
