document.addEventListener('facetwp-loaded', function () {
  if (document.querySelector('.facetwp-page.first.active')) {
    const paginationLinkprev = document.createElement('div');
    paginationLinkprev.classList.add(
      'facetwp-page',
      'prev',
      'facetwp-page-disable'
    );
    paginationLinkprev.innerHTML = 'Page précédente';
    document.querySelector('.facetwp-pager').prepend(paginationLinkprev);
  }

  if (document.querySelector('.facetwp-page.last.active')) {
    const paginationLinknext = document.createElement('div');
    paginationLinknext.classList.add(
      'facetwp-page',
      'next',
      'facetwp-page-disable'
    );
    paginationLinknext.innerHTML = 'Page suivante';
    document.querySelector('.facetwp-pager').appendChild(paginationLinknext);
  }
});
