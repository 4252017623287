import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', function () {
  const bodyContainer = document.querySelector('body');
  const alertsBar = document.getElementById('full-width-alert');
  const alertsBarItems = document.querySelectorAll('.alert-bar__columns');
  const alertsBarPagination = document.querySelector('.alert-controls');

  if (1 <= alertsBarItems.length) {
    bodyContainer.classList.add('alerts-bar-active');
  }

  new Swiper('#full-width-alert', {
    loop: true,
    autoplay: {
      delay: 7000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      slideChangeTransitionStart() {
        paginationColor();
      },
    },
  });

  const closeAlert = document.querySelector('.close-alert');

  if (closeAlert) {
    closeAlert.addEventListener('click', function () {
      closeAlertBar();
    });
  }

  function heightAlertBar() {
    const alertHeight = alertsBar.offsetHeight;
    alertsBar.style.setProperty('--AlertHeight', alertHeight + 'px');
    document.documentElement.style.setProperty(
      '--AlertHeight',
      alertHeight + 'px'
    );
  }

  function closeAlertBar() {
    document.querySelector('.full-width-alert').remove();
    bodyContainer.classList.remove('alerts-bar-active');
  }

  function paginationColor() {
    const curentSlideColor = document.querySelector('.swiper-slide-active')
      .style.color;
    if ('white' === curentSlideColor) {
      alertsBarPagination.classList.add('alert-controls__light');
    } else {
      alertsBarPagination.classList.remove('alert-controls__light');
    }
  }

  paginationColor();
  heightAlertBar();

  window.addEventListener('resize', function () {
    heightAlertBar();
  });
});
